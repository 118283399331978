<template>
  <b-card title="NU AANVRAGEN" tag="article" class="mb-2 w-100 item-planner">
    <b-card-text>
      <div>
        <span v-if="!itemPlannerLoaded && isLoggedIn">
          <font-awesome-icon class="fa-spin" far icon="spinner" />
        </span>
        <p v-if="!isLoggedIn">
          Om dit product te reserveren moet je ingelogd zijn. Heb je nog geen
          account?
          <router-link :to="{ name: 'register' }"
            >Registreer je dan nu!</router-link
          >
        </p>
        <b-form v-if="isLoggedIn && itemPlannerLoaded">
          <b-row>
            <b-col sm="12" md="6">
              <label>Afhaaldatum</label>
              <calendar
                v-if="dateEditable"
                v-model="startDate"
                :disabled-days-of-week="disabled"
                show-date-only
                :format="format"
                :clear-button="clear"
                :placeholder="placeholder"
                :pane="1"
                :min-date="minFetchDate"
                :max-date="maxFetchDate"
                :disabled-days="disabledDates"
                :input-disabled="true"
                :lang="lang"
                :position="position"
                :range-bus="getBus"
                :range-status="1"
                width="100%"
                :disabled="dateEditable"
              ></calendar>
              <b-form-input
                v-if="!dateEditable"
                v-model="formattedStartDate"
                class="input-group"
                disabled
              />
            </b-col>
            <b-col sm="12" md="6">
              <label>Terugbrengdatum</label>
              <calendar
                v-if="dateEditable"
                v-model="endDate"
                :disabled-days-of-week="disabled"
                show-date-only
                :format="format"
                :clear-button="clear"
                :min-date="minReturnDate"
                :max-date="maxReturnDate"
                :input-disabled="true"
                :disabled-days="disabledDates"
                :placeholder="placeholder"
                :pane="1"
                :position="position"
                :lang="lang"
                :range-bus="getBus"
                :range-status="2"
                width="100%"
              ></calendar>
              <b-form-input
                v-if="!dateEditable"
                v-model="formattedEndDate"
                class="input-group"
                disabled
              />
            </b-col>
            <b-col cols="12">
              <p v-if="!dateLocked" class="price-comment mt-3">
                Deze data zijn al vastgelegd omdat je al één of meerdere
                artikelen in je winkelmandje geplaatst hebt.
              </p>
            </b-col>
            <b-col></b-col>
          </b-row>
        </b-form>
        <div
          v-if="startDate && endDate && isLoggedIn && itemPlannerLoaded"
          class="mt-3"
        >
          <b-tabs v-model="tabIndex" content-class="mt-3" class="nav-justified">
            <b-tab
              v-for="warehouse in warehouses"
              :key="warehouse"
              :title="warehouse"
              :active="currentWarehouse == warehouse"
            >
              <div v-if="partiallyAvailable" class="partially-available">
                <h5>Gedeeltelijk beschikbaar in {{ warehouse }}</h5>
                <p>
                  Eén of meerdere andere items in je het pakket zijn niet
                  beschikbaar in deze afhaallocatie.
                </p>
              </div>
              <div v-if="item.Type == 'Package'">
                <ItemPlannerItem
                  v-for="packageItem in requestedItemData.Items"
                  :key="packageItem.ID"
                  ref="itemPlanner"
                  :item="packageItem"
                  :amount-of-items="
                    item.Items.find(ogItem => ogItem.ID === packageItem.ID)
                      .Amount
                  "
                  :start-date="startDate"
                  :end-date="endDate"
                  :date-editable="dateEditable"
                  :current-warehouse="currentWarehouse"
                  :selected-warehouse="warehouse"
                  :item-type="item.Type"
                  :updating-item="updatingItem"
                  :start-date-available-time-periods="
                    startDateAvailableTimePeriods[warehouse]
                  "
                  :end-date-available-time-periods="
                    endDateAvailableTimePeriods[warehouse]
                  "
                  @updatePrice="calculatePrice({ warehouse: warehouse })"
                  @updateItemData="refreshItemData"
                />
              </div>
              <div v-else>
                <ItemPlannerItem
                  ref="itemPlanner"
                  :item="requestedItemData"
                  :start-date="startDate"
                  :end-date="endDate"
                  :date-editable="dateEditable"
                  :current-warehouse="currentWarehouse"
                  :selected-warehouse="warehouse"
                  :updating-item="updatingItem"
                  :disabled-dates="disabledDates"
                  :invalid-date="invalidDate"
                  :special-dates="closedDates"
                  item-type="Item"
                  :start-date-available-time-periods="
                    startDateAvailableTimePeriods[warehouse]
                  "
                  :end-date-available-time-periods="
                    endDateAvailableTimePeriods[warehouse]
                  "
                  @updateItemData="refreshItemData"
                />
              </div>
              <b-button
                v-if="item.Type && item.Type == 'Package'"
                href="#"
                class="mt-3"
                variant="primary"
                :disabled="updatingItem"
                @click="addToCartRecursive()"
              >
                In Winkelmandje
                <font-awesome-icon
                  v-if="updatingItem"
                  class="fa-spin"
                  far
                  icon="spinner"
                />
              </b-button>
              <h4 v-if="item.Type && item.Type == 'Package'" class="mt-3">
                Prijs:
                {{
                  (Math.round((price + Number.EPSILON) * 100) / 100)
                    .toFixed(2)
                    .replace('.', ',')
                }}
                euro
              </h4>
              <div v-if="item.Type && item.Type == 'Package'">
                <hr />
                <p class="price-comment">
                  Getoonde prijzen zijn excl. administratiekost 10 euro
                </p>
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </b-card-text>
  </b-card>
</template>
<script>
import calendar from 'vue2-slot-calendar/lib/calendar'
import ItemPlannerItem from './ItemPlannerItem'
import { getItem } from '@/services/ItemService'
import { addToCartMultiple, getCart } from '@/services/CartService'
import getPreviousWorkDay from '@/functions/rentaldates/getPreviousWorkDay'
import getMaxFetchDate from '@/functions/rentaldates/getMaxFetchDate'
import getMaxReturnDate from '@/functions/rentaldates/getMaxReturnDate'
import getMinFetchDate from '@/functions/rentaldates/getMinFetchDate'
import getMinReturnDate from '@/functions/rentaldates/getMinReturnDate'
import getNextAvailableDate from '@/functions/rentaldates/getNextAvailableDate'
import dayjs from 'dayjs'
import Vue from 'vue'
import getCustClosedRentalDates from '@/services/cust/GetCustClosedRentalDates'
import BusinessDayHourCheck from '@/services/item/BusinessDayHourCheck'

export default {
  components: {
    calendar,
    ItemPlannerItem
  },
  props: {
    item: {
      required: true,
      type: Object
    }
  },
  translations(lang) {
    lang = lang || 'nl'
    let text = {
      daysOfWeek: ['Zo', 'Ma', 'Di', 'Wo', 'Do', 'Vr', 'Za'],
      limit: 'Limiet bereikt ({{limit}} max artikelen).',
      loading: 'Laden...',
      minLength: 'Min. Lengte',
      months: [
        'Januari',
        'Februari',
        'Maart',
        'April',
        'Mei',
        'Juni',
        'Juli',
        'Augustus',
        'September',
        'October',
        'November',
        'December'
      ],
      notSelected: 'Niets geselecteerd',
      required: 'Vereist',
      search: 'Zoeken'
    }
    return window.VueCalendarLang ? window.VueCalendarLang(lang) : text
  },
  data() {
    const startDate = this.$store.getters.DateTimeBusinessStart.replace(
      /-/g,
      '/'
    )
    const endDate = this.$store.getters.DateTimeBusinessEnd.replace(/-/g, '/')
    return {
      startDate,
      endDate,
      disabled: [0, 6],
      position: 'left',
      disabledDates: [],
      format: 'dd/MM/yyyy',
      clear: false,
      lang: 'nl',
      placeholder: 'Selecteer een datum',
      bus: new Vue(),
      requestedItemData: {},
      price: 0.0,
      partiallyAvailable: false,
      updatingItem: false,
      selectedWarehouse: 'Asse',
      tabIndex: 0,
      itemReferences: {},
      itemPlannerLoaded: false,
      closedDates: [],
      startDateAvailableTimePeriods: {},
      endDateAvailableTimePeriods: {}
    }
  },
  computed: {
    warehouses: function() {
      return this.item.WarehouseIDs
    },
    formattedStartDate: function() {
      return this.startDate.replace(/-/g, '/')
    },
    formattedEndDate: function() {
      return this.endDate.replace(/-/g, '/')
    },
    minFetchDate: function() {
      return getMinFetchDate()
    },
    maxFetchDate: function() {
      return getMaxFetchDate()
    },
    minReturnDate: function() {
      return getMinReturnDate(this.startDate)
    },
    maxReturnDate: function() {
      return getMaxReturnDate(this.startDate)
    },
    isLoggedIn: function() {
      if (this.$store.getters.user.UserName) {
        return true
      } else {
        return false
      }
    },
    adminCosts: function() {
      const cartItems = this.$store.getters.cartCache.CartItems.Collection

      if (cartItems.find(cartItem => cartItem.ItemID === 'ADMIN')) return true
      return false
    },
    dateEditable: function() {
      if (this.updatingItem) return false
      return this.$store.getters.cartCount < 2
    },
    dateLocked: function() {
      return this.$store.getters.cartCount < 2
    },

    currentWarehouse: function() {
      if (this.$store.getters.cartCache) {
        return this.$store.getters.cartCache.WarehouseID
          ? this.$store.getters.cartCache.WarehouseID
          : ''
      } else {
        return ''
      }
    },
    invalidDate: function() {
      if (this.endDate === 'Invalid date') return true
      if (this.startDate === 'Invalid date') return true

      return false
    }
  },
  watch: {
    selectedWarehouse() {
      if (this.isLoggedIn) {
        this.getClosedRentalDates()
      }
    },
    tabIndex: function() {
      this.selectedWarehouse = this.warehouses[this.tabIndex]
      this.calculatePrice()
    },
    price: async function() {
      if (
        this.item.Type &&
        this.item.Type == 'Package' &&
        this.$refs.itemPlanner
      ) {
        let activeItems = this.$refs.itemPlanner
          .filter(item => item.warehouse.WarehouseID == this.currentWarehouse)
          .filter(item => item.warehouse.HasStock)
        if (activeItems.length != this.item.Items.length) {
          this.partiallyAvailable = true
        } else {
          this.partiallyAvailable = false
        }
      } else {
        this.partiallyAvailable = false
      }
    },
    startDate: async function() {
      if (this.dateEditable) {
        this.endDate = await dayjs(this.startDate, 'DD/MM/YYYY')
          .add(7, 'days')
          .format('DD/MM/YYYY')

        if (
          dayjs(this.startDate, 'DD/MM/YYYY').isAfter(
            dayjs(this.minFetchDate, 'DD/MM/YYYY').add(6, 'months')
          )
        ) {
          this.startDate = getPreviousWorkDay(
            dayjs(this.minFetchDate, 'DD/MM/YYYY')
          ).format('DD/MM/YYYY')
        }

        if (this.startDate && this.endDate) {
          this.requestedItemData = await getItem({
            itemID: this.$route.params.itemID,
            startDate: this.startDate,
            endDate: this.endDate,
            slug: true
          })
          this.itemPlannerLoaded = true
        }
      }
    },
    endDate: async function(newEndDate, oldEndDate) {
      if (this.endDate === this.startDate || oldEndDate === '') return
      this.checkTimePeriods()
      await this.getClosedRentalDates()

      if (
        this.disabledDates.includes(
          dayjs(this.endDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
        )
      ) {
        this.endDate = getNextAvailableDate(
          dayjs(this.endDate, 'DD/MM/YYYY'),
          this.disabledDates
        ).format('DD/MM/YYYY')
      }

      if (dayjs(this.endDate, 'DD/MM/YYYY'))
        if (
          dayjs(this.endDate, 'DD/MM/YYYY').isAfter(
            dayjs(this.startDate, 'DD/MM/YYYY').add(60, 'days')
          )
        ) {
          this.endDate = getPreviousWorkDay(
            dayjs(this.startDate, 'DD/MM/YYYY').add(60, 'days'),
            this.disabledDates
          ).format('DD/MM/YYYY')
        }
      await this.refreshItemData()

      this.$store.commit('setDateTimeBusinessStart', {
        date: this.startDate
      })

      this.$store.commit('setDateTimeBusinessEnd', {
        date: this.endDate
      })

      // or generate/simulate a native events (not sure how, but its outside Vue's realm I think
    }
  },
  async created() {
    if (this.isLoggedIn) {
      await this.getClosedRentalDates()
    }

    if (this.startDate && this.endDate && this.isLoggedIn ) {
      this.requestedItemData = await getItem({
        itemID: this.$route.params.itemID,
        startDate: this.startDate,
        endDate: this.endDate,
        slug: true
      })
      this.itemPlannerLoaded = true
    }
    this.checkTimePeriods()
  },
  methods: {
    async checkTimePeriods() {
      if (!this.itemPlannerLoaded) return
      let startDateAvailableTimePeriods = await BusinessDayHourCheck({
        date: dayjs(this.startDate, 'DD/MM/YYYY')
          .subtract('1', 'days')
          .format('YYYY-MM-DD')
      })
      let endDateAvailableTimePeriods = await BusinessDayHourCheck({
        date: dayjs(this.endDate, 'DD/MM/YYYY')
          .subtract(1, 'days')
          .format('YYYY-MM-DD')
      })

      const parsedStartDateAvailableTimePeriods = {}
      const parsedEndDateAvailableTimePeriods = {}

      for (const warehouse of startDateAvailableTimePeriods) {
        parsedStartDateAvailableTimePeriods[warehouse.WarehouseID] =
          warehouse.Status
      }

      for (const warehouse of endDateAvailableTimePeriods) {
        parsedEndDateAvailableTimePeriods[warehouse.WarehouseID] =
          warehouse.Status
      }

      this.startDateAvailableTimePeriods = parsedStartDateAvailableTimePeriods
      this.endDateAvailableTimePeriods = parsedEndDateAvailableTimePeriods
    },
    async getClosedRentalDates() {
      const startDate = dayjs().format('YYYY-MM-DD')
      const endDate = dayjs(this.startDate, 'DD/MM/YYYY')
        .add(6, 'months')
        .format('YYYY-MM-DD')

      const result = await getCustClosedRentalDates({
        startDate: startDate,
        endDate: endDate,
        warehouse: this.selectedWarehouse
      })

      this.disabledDates = result.map(date => {
        return dayjs(date).format('YYYY-MM-DD')
      })
    },
    getBus() {
      return this.bus
    },
    async addToCartRecursive() {
      this.updatingItem = true

      let cartItems = []

      for (let index in this.$refs.itemPlanner
        .filter(item => item.warehouse.WarehouseID == this.selectedWarehouse)
        .filter(item => item.warehouse.HasStock)) {
        let item = this.$refs.itemPlanner[index].item
        let selectedWarehouse = this.$refs.itemPlanner[index].selectedWarehouse
        let amount = await this.$store.getters.getItemStoreItem(
          `${item.ID}-${selectedWarehouse}`
        ).Amount

        const maxCustomerStock = item.Warehouses.filter(
          warehouse => warehouse.WarehouseID === this.selectedWarehouse
        )[0].MaxCustomerStock

        if (amount > maxCustomerStock) {
          continue
        }

        if (amount > 0) {
          if (item.CompositionID) {
            cartItems.push({
              compositionID: item.CompositionID,
              itemID: null,
              dateTimeBusinessStart: this.startDate,
              dateTimeBusinessEnd: this.endDate,
              warehouseID: this.selectedWarehouse,
              amount
            })
          } else {
            cartItems.push({
              itemID: item.ID,
              dateTimeBusinessStart: this.startDate,
              dateTimeBusinessEnd: this.endDate,
              warehouseID: this.selectedWarehouse,
              amount
            })
          }
        }
        this.$store.commit('resetStoreItem', {
          key: `${item.ID}-${selectedWarehouse}`
        })
      }
      await addToCartMultiple({ cartItems })
      await this.refreshItemData()
      await getCart()
      this.updatingItem = false
    },
    calculatePrice: function() {
      if (!this.$refs.itemPlanner) {
        return
      }
      let activeItems = this.$refs.itemPlanner
        .filter(item => item.warehouse.WarehouseID == this.selectedWarehouse)
        .filter(item => item.warehouse.HasStock)
        .map(item => item.warehouse.Price)

      if (activeItems.length > 0)
        this.price = activeItems.reduce((prev, next) => prev + next)
      else this.price = 0.0
    },
    refreshItemData: async function() {
      this.updatingItem = true

      this.requestedItemData = await getItem({
        itemID: this.$route.params.itemID,
        startDate: this.startDate,
        endDate: this.endDate,
        slug: true,
      })
      this.updatingItem = false
    }
  }
}
</script>
<style scoped lang="scss">
.price-comment {
  font-size: 12px;
  font-family: 'Roboto' !important;
  font-weight: 200;
}
.datepicker {
  width: 100%;
}
.nav-tabs {
  width: 100%;
  margin-left: -20px;
}
.card {
  border: 5px solid black !important;
  border-radius: 5px;
  h4,
  label,
  p,
  .btn {
    font-family: 'DecimaPro-Bold';
    text-decoration: none;
  }
}

.partially-available {
  h5 {
    font-family: 'Roboto';
    font-size: 18px;
  }
  p {
    font-family: 'Roboto';
    font-size: 14px;
  }
}
</style>
