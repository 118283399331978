import { render, staticRenderFns } from "./ItemPlanner.vue?vue&type=template&id=376f2fb5&scoped=true&"
import script from "./ItemPlanner.vue?vue&type=script&lang=js&"
export * from "./ItemPlanner.vue?vue&type=script&lang=js&"
import style0 from "./ItemPlanner.vue?vue&type=style&index=0&id=376f2fb5&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "376f2fb5",
  null
  
)


    import installComponents from "!D:/IIS/rentmagic.website.vue.vlaams-brabant/test/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BFormInput, BCol, BRow, BForm, BButton, BTab, BTabs, BCardText, BCard} from 'bootstrap-vue'
    installComponents(component, {BFormInput, BCol, BRow, BForm, BButton, BTab, BTabs, BCardText, BCard})
    

export default component.exports